<!-- 订单申诉驳回弹窗 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'申诉处理':'申诉处理'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <!-- 表格 -->
        <!-- 数据表格 -->
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :loading="loading1"
        >
          <!--结算方式-->
          <template v-slot:istongguo= "{ scope }">
            <el-radio v-model="scope.row.istongguo" label="1">是</el-radio>
            <el-radio v-model="scope.row.istongguo" label="2">否</el-radio>
          </template>
        </my-table>

        <div style="margin-top: 10px;">
          <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">其他申述理由：</span>
          <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">这是一条其他申述理由这是一条其他申述理由这是一条其他申述理由这是一条其他申述理由</span>
        </div>
        <div style="margin-top: 10px;">
          <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">申诉处理说明：</span>
        </div>
        <div style="margin-top: 10px;">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入内容"
              v-model="textarea2">
          </el-input>
        </div>
      </el-form>


      <div slot="footer">
        <el-button
            @click="reAudit()">重新审核
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">处理审核
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        width="40%"
        center
        :visible="reAppeal"
        :before-close="handleClose"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'订单审核':'订单审核'">

      <el-form
        ref="form"
        :model="form"
        label-width="100px">

        <el-row :gutter="15" v-if="status == 0">
          <el-col :span="12">
            <div style="background: #36dd9e;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;">
              <img src="@/assets/images/home/hege.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
              <span style="font-size: 20px;font-weight: 500;color: #ffffff; vertical-align: middle;margin-left: 10px;">合格</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="background: #DADADA;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified(1)">
              <img src="@/assets/images/home/buhege.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
              <span style="font-size: 20px;font-weight: 500;color: #3F4155; vertical-align: middle;margin-left: 10px;">不合格</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="status == 0" style="margin-top: 20px;">
          <el-col :span="8">
            <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
              <span>合同金额：</span>
              <span>{{data.contract_price}}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
              <span>合计补扣款：</span>
              <span>{{Repairthread}}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
              <span>应付额：</span>
              <span>{{data.pay_price}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="status == 0">
          <div style="background: #f7f8fa;border-radius: 6px;padding: 15px;margin-top: 20px;margin-bottom: 20px;">
            <el-row v-for="(item,index) in form.pass" :key="index">
              <el-col :span="12" style="margin-top: 7px;">
                <el-checkbox v-model="item.checked" @change="selectcheckbox(item,index)">{{item.label}}</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-form-item label="补扣款">
                  <el-input type="number" v-model="item.figure" placeholder="请输入">
                    <template slot="append">
                      <span v-if="item.mode == 'proportion'">百分比</span>
                      <span v-else>固定金额</span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" style="margin-top: 7px;">
                <el-checkbox v-model="checked1" @change="selectcheckbox">其他</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-form-item label="补扣款">
                  <el-input type="number" v-model="kuanxiang" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checked1 == true">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入其他原因"
                v-model="form.other">
              </el-input>
            </el-row>
          </div>
        </el-row>


        <el-row :gutter="15" v-if="status == 1">
          <el-col :span="12">
            <div style="background: #DADADA;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified(0)">
              <img src="@/assets/images/home/hegeactive.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
              <span style="font-size: 20px;font-weight: 500;color: #3F4155; vertical-align: middle;margin-left: 10px;">合格</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="background: #F54E71;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified()">
              <img src="@/assets/images/home/buhegeactive.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
              <span style="font-size: 20px;font-weight: 500;color: #FFFFFF; vertical-align: middle;margin-left: 10px;">不合格</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="status == 1" style="margin-top: 20px;">
          <el-col :span="8">
            <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
              <span>合同金额：</span>
              <span>{{data.contract_price}}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
              <span>合计补扣款：</span>
              <span>{{Repairthread}}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
              <span>应付额：</span>
              <span>{{data.pay_price}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="status == 1">
          <div style="background: #f7f8fa;border-radius: 6px;padding: 15px;margin-top: 20px;margin-bottom: 20px;">
            <el-row v-for="(item,index) in form.flunk" :key="index">
              <el-col :span="12" style="margin-top: 7px;">
                <el-checkbox v-model="item.checked" @change="buhegeselectcheckbox(item,index)">{{item.label}}</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-form-item label="补扣款">
                  <el-input type="number" v-model="item.figure" placeholder="请输入">
                    <template slot="append">
                      <!--ceshi-->
                      <span v-if="item.mode == 'proportion'">百分比</span>
                      <span v-else>固定金额</span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" style="margin-top: 7px;">
                <el-checkbox v-model="checked2" @change="buhegeselectcheckbox">其他</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-form-item label="补扣款">
                  <el-input type="number" v-model="kuanxiang" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checked2 == true">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入其他原因"
                v-model="form.other">
              </el-input>
            </el-row>
          </div>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button
            @click="auditCancellation()">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="reviewedAndConfirmed">确定
        </el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>
import {audit, disqualification, getcheck_assist} from "@/api/orderModule"
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,


      // 表格数据
      List: [
        {
          xiang:'拖车目的地照片缺失',
          bukoukuan:'-30',
          shensuliyou:'照片已上传',
          istongguo:0
        },
        {
          xiang:'拖车目的地照片缺失',
          bukoukuan:'-30',
          shensuliyou:'照片已上传',
          istongguo:0
        },
        {
          xiang:'拖车目的地照片缺失',
          bukoukuan:'-30',
          shensuliyou:'照片已上传',
          istongguo:1
        },
      ],
      // 总条目数
      total: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        {
          label: "订单补扣款项",
          prop: "xiang",
          isShow: true
        },
        {
          label: "补扣款",
          prop: "bukoukuan",
          isShow: true
        },
        {
          label: "申述理由",
          prop: "shensuliyou",
          isShow: true
        },
        {
          label: "是否通过申述",
          prop: "istongguo",
          slot:'istongguo',
          isShow: true
        },
      ],

      //重新申诉弹窗
      reAppeal:false,

      radio: '1',
      input:'',
      textarea2:'',

      status:0,

      //补扣额
      Repairthread:0,
      kuanxiang:0,


      checked1:false,
      checked2:false,

      //合格and不合格 pass为合格  flunk为不合格
      type:'pass',
      // 传递的数组 那些选中了
      repair_thread:[],

    };
  },
  watch: {

  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = {};
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    //调用获取配置
    this.getConfig();

    this.loading1 = false;
  },
  methods: {
    // 获取审核配置
    getConfig(){
      let where = {
        odd: this.data.order_id
      }
      getcheck_assist(this.data.id,where).then(res => {
        console.log(res);
        this.form = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //     this.loading = true;
      //     this.updateVisible(false);
      //     this.$emit('done');
      //     this.loading = false;
      //   } else {
      //     return false;
      //   }
      // });

      //显示重新申诉弹窗
      this.reAppeal = false;
      this.$emit('update:visible', false);
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },

    //关闭重新申诉弹窗
    handleClose(){
      this.reAppeal = false;
      this.$emit('update:visible', true);
    },
    //点击取消按钮
    cancel(){
      this.reAppeal = false;
      this.$emit('update:visible', true);
    },
    //点击确定按钮
    determine(){
      this.reAppeal = false;
      this.$emit('update:visible', true);
    },

    //点击重新审核
    reAudit(){
      console.log(11111)
      this.reAppeal = true;
      // this.$emit('update:visible', false);
    },

    auditCancellation(){
      this.reAppeal = false;
      this.$emit('update:visible', true);
    },
    reviewedAndConfirmed(){
      // 审核合格
      if(this.type == 'pass'){
        let where = {
          odd: this.data.order_id,
          repair_thread_price: this.Repairthread,
          other: this.form.other,
          repair_thread: this.repair_thread,
        }
        audit(this.data.id,where).then(res => {
          if(res.code == 200){
            this.loading = true;
            this.reAppeal = false;
            this.$emit('update:visible', true);
            this.loading = false;
            this.$message.success(res.msg);
          }else {
            this.loading = false;
            this.$message.error(res.msg)
          }
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.msg)
        })
      }else {
        let where = {
          odd: this.data.order_id,
          repair_thread_price: this.Repairthread,
          other: this.form.other,
          repair_thread: this.repair_thread,
        }
        disqualification(this.data.id,where).then(res => {
          if(res.code == 200){
            this.loading = true;
            this.reAppeal = false;
            this.$emit('update:visible', true);
            this.loading = false;
            this.$message.success(res.msg);
          }else {
            this.loading = false;
            this.$message.error(res.msg)
          }
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.msg)
        })
      }
    },

    //合格 点击多选
    selectcheckbox(item,index){
      // console.log(item);
      // proportion百分比  immobilization固定

      //用户传递的数组
      this.repair_thread = [];
      // 声明一个用于存放固定金额勾选的数据
      let data = [];
      // 百分比
      // let percentage = [];
      // 循环获取那些项勾选了
      this.form.pass.forEach(item => {
        if(item.checked == true){
          // 判断是固定金额还是百分比
          if(item.mode == 'immobilization'){
            // 将拿到的值转换float类型用于计算
            // item.figure = parseFloat(item.figure);
            data.push(item.figure)
          }else if(item.mode == 'proportion'){
            console.log(item)
            this.data.pay_price = parseFloat(this.data.pay_price);
            // item.figure = parseFloat(item.figure);
            let a = item.figure / 100;
            let b = a * this.data.pay_price;
            console.log(item.figure)
            data.push(b)
          }
          //那些项选中了
          this.repair_thread.push(item);
        }
      })
      // 其他是否勾选了
      if(this.checked1 == true){
        console.log(this.kuanxiang)
        this.kuanxiang = parseFloat(this.kuanxiang);
        data.push(this.kuanxiang);
      }
      console.log(data);
      //计算总和
      this.Repairthread = data.reduce(function(acc, val) {
        return acc + val;
      }, 0);
      console.log(this.Repairthread,'合同补扣款')
    },

    //点击不合格
    Unqualified(number){
      this.status = number;
      if(this.status == 0){
        this.type = 'pass'
      }else {
        this.type = 'flunk'
      }
    },

    //选择不合格的项
    buhegeselectcheckbox(item,index){
      //用户传递的数组
      this.repair_thread = [];
      // 声明一个用于存放固定金额勾选的数据
      let data = [];
      // 循环获取那些项勾选了
      this.form.flunk.forEach(item => {
        if(item.checked == true){
          // 判断是固定金额还是百分比
          if(item.mode == 'immobilization'){
            // 将拿到的值转换float类型用于计算
            // item.figure = parseFloat(item.figure);
            data.push(item.figure)
          }else if(item.mode == 'proportion'){
            console.log(item)
            this.data.pay_price = parseFloat(this.data.pay_price);
            // item.figure = parseFloat(item.figure);
            let a = item.figure / 100;
            let b = a * this.data.pay_price;
            console.log(item.figure)
            data.push(b)
          }
          //那些项选中了
          this.repair_thread.push(item);
        }
      })
      // 其他是否勾选了
      if(this.checked2 == true){
        console.log(this.kuanxiang)
        this.kuanxiang = parseFloat(this.kuanxiang);
        data.push(this.kuanxiang);
      }
      console.log(data);
      //计算总和
      this.Repairthread = data.reduce(function(acc, val) {
        return acc + val;
      }, 0);
      console.log(this.Repairthread,'合同补扣款')
    },

  }
}
</script>

<style scoped lang="scss">

</style>
